exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-form-contact-tsx": () => import("./../../../src/pages/form/contact.tsx" /* webpackChunkName: "component---src-pages-form-contact-tsx" */),
  "component---src-pages-form-feedback-tsx": () => import("./../../../src/pages/form/feedback.tsx" /* webpackChunkName: "component---src-pages-form-feedback-tsx" */),
  "component---src-pages-form-referral-tsx": () => import("./../../../src/pages/form/referral.tsx" /* webpackChunkName: "component---src-pages-form-referral-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-self-help-tsx": () => import("./../../../src/pages/self-help.tsx" /* webpackChunkName: "component---src-pages-self-help-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-warranty-tsx": () => import("./../../../src/pages/warranty.tsx" /* webpackChunkName: "component---src-pages-warranty-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-categorie-template-tsx": () => import("./../../../src/templates/categorie-template.tsx" /* webpackChunkName: "component---src-templates-categorie-template-tsx" */),
  "component---src-templates-feature-template-tsx": () => import("./../../../src/templates/feature-template.tsx" /* webpackChunkName: "component---src-templates-feature-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/product-template.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/project-template.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-service-template-tsx": () => import("./../../../src/templates/service-template.tsx" /* webpackChunkName: "component---src-templates-service-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

