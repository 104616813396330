/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import "./src/styles/css/bootstrap.min.css"
import "animate.css"
import "./src/styles/css/boxicons.min.css"
import "./src/styles/css/flaticon.css"
import "react-accessible-accordion/dist/fancy-example.css"
import "/node_modules/aos/dist/aos.css"
import "swiper/css"
import "swiper/css/bundle"
import "./src/styles/css/slick.css"

// Global style
import "./src/styles/css/style.css"
import "./src/styles/css/responsive.css"
import React from "react"
import RootElement from "./src/components/RootElement"

export const wrapRootElement = ({ element }: { element: any }) => {
  return <RootElement>{element}</RootElement>
}

// If you want RTL style comment out the below line
// import './src/styles/css/rtl.css'

// If you want Multicolor comment out single
// import './src/styles/css/colors/brink-pink-style.css'
// import './src/styles/css/colors/pink-style.css'
// import './src/styles/css/colors/purple-style.css'
